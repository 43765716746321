<script setup lang="ts">
const { getCommonLayoutData } = usePlayaLayout()

defineLayout({
  label: 'Kimpton Layout',
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)

const {
  resorts,
  currentResort,
} = await getCommonLayoutData(isVoixTemplatizer, ['brands', 'footerCompanyMenu', 'footerResourcesMenu', 'footerTermsMenu'])

provide('accentColor', { value: '#cfac6a' })
provide('currentResort', currentResort)
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white playaresorts-theme"
    >
      <div id="app" class="bg-[#f5f5f5]">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />
        <div id="kimpton-content">
          <KimptonNavigation
            v-if="!isVoixTemplatizer"
            :current-resort="currentResort?.data"
            class="font-sans"
            role="landmark"
          />

          <div class="text-gray-500">
            <slot />
          </div>

          <KimptonFooter v-if="!isVoixTemplatizer" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/kimpton.scss';
</style>
